"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const core_1 = require("@/core");
function Contact() {
    const { register, handleSubmit, formState: { errors }, } = (0, react_hook_form_1.useForm)();
    const onSubmit = (data, e) => {
        e.target.reset();
        e.target.remove();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        document.getElementById('msg').innerHTML =
            'Thank you!<br/>Your message was sent!';
    };
    const form = (0, react_1.useRef)();
    return (react_1.default.createElement(core_1.Layout, { title: "Contact" },
        react_1.default.createElement("div", { id: "page-wrapper", className: "page contact min-h-screen pt-7" },
            react_1.default.createElement("h2", null, "Contact"),
            react_1.default.createElement("p", { id: "msg" }, "I'll get back as soon as possible."),
            react_1.default.createElement("form", { id: "contact-form", className: "mb-5", onSubmit: handleSubmit(onSubmit), ref: form },
                react_1.default.createElement("div", { className: "pb-5" },
                    react_1.default.createElement("label", null,
                        "Name",
                        errors.name && (react_1.default.createElement("span", { className: "pl-1 font-black text-red-500" }, "*"))),
                    react_1.default.createElement("input", { className: "mt-1 w-full rounded-md border-2 border-gray-300 px-4 py-3", type: "text", ...register('name', { required: true }) })),
                react_1.default.createElement("div", { className: "pb-5" },
                    react_1.default.createElement("label", null,
                        "Email",
                        errors.email && (react_1.default.createElement("span", { className: "pl-1 font-black text-red-500" }, "*"))),
                    react_1.default.createElement("input", { className: "mt-1 w-full rounded-md border-2 border-gray-300 px-4 py-3", type: "email", ...register('email', { required: true }) })),
                react_1.default.createElement("div", { className: "pb-5" },
                    react_1.default.createElement("label", null,
                        "Message",
                        errors.message && (react_1.default.createElement("span", { className: "pl-1 font-black text-red-500" }, "*"))),
                    react_1.default.createElement("textarea", { className: "mt-1 w-full rounded-md border-2 border-gray-300 px-4 py-3", rows: 6, ...register('message', { required: true }) })),
                react_1.default.createElement("div", { className: "pb-5 text-right" },
                    react_1.default.createElement("input", { className: "button cursor-pointer rounded-md bg-gray-200 py-2 px-4 text-center font-light", type: "submit" }))))));
}
exports.default = Contact;
