"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nav = void 0;
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const react_2 = require("@headlessui/react");
const core_1 = require("@/core");
const ri_1 = require("react-icons/ri");
const Nav = () => {
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("nav", { className: "full-page  w-full py-3 md:block" },
            react_1.default.createElement("div", { className: "flex flex-row justify-end" },
                react_1.default.createElement("ul", { className: "hidden space-x-3 md:inline-flex" },
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(core_1.Button, { to: "/" }, "Home")),
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(core_1.Button, { to: "/resume" }, "Resume")),
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(core_1.Button, { to: "/portfolio" }, "Portfolio")),
                    react_1.default.createElement("li", { hidden: true },
                        react_1.default.createElement(core_1.Button, { href: "https://github.com/moquette/", className: "pt-0" }, "GitHub")),
                    react_1.default.createElement("li", { hidden: true },
                        react_1.default.createElement(core_1.Button, { href: "https://www.linkedin.com/in/jmoquette/", className: "pt-0" }, "LinkedIn")),
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(core_1.Button, { to: "/contact", className: "pt-0" }, "Contact"))),
                react_1.default.createElement(react_2.Popover, { className: "popover" }, ({ open }) => (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(react_2.Popover.Button, { className: `popover-button ${open ? `` : `rotate-90 transform `}` },
                        react_1.default.createElement("svg", { width: "24", height: "24", fill: "none", "aria-hidden": "true" },
                            react_1.default.createElement("path", { d: "M12 \n                      6v.01M12 12v.01M12 18v.01M12 \n                      7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 \n                      6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 \n                      6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z", stroke: "currentColor" }))),
                    react_1.default.createElement(react_2.Transition, { as: react_1.Fragment, enter: "transition ease-out duration-250", enterFrom: "opacity-0 translate-y-1", enterTo: "opacity-100 translate-y-0", leave: "transition ease-in duration-250", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-1" },
                        react_1.default.createElement(react_2.Popover.Panel, { className: "popover-panel" },
                            react_1.default.createElement("div", { className: "wrapper" },
                                react_1.default.createElement("div", null,
                                    react_1.default.createElement(react_2.Popover.Button, { as: react_router_dom_1.NavLink, to: "/", className: "navbar-link" },
                                        react_1.default.createElement(ri_1.RiHome2Line, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "Home"),
                                    react_1.default.createElement(react_2.Popover.Button, { as: react_router_dom_1.NavLink, to: "/resume", className: "navbar-link" },
                                        react_1.default.createElement(ri_1.RiQuillPenLine, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "Resume"),
                                    react_1.default.createElement(react_2.Popover.Button, { as: react_router_dom_1.NavLink, to: "/portfolio", className: "navbar-link" },
                                        react_1.default.createElement(ri_1.RiLayoutTop2Line, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "Portfolio"),
                                    react_1.default.createElement(react_2.Popover.Button, { as: react_router_dom_1.NavLink, to: "/contact", className: "navbar-link" },
                                        react_1.default.createElement(ri_1.RiMailLine, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "Contact"),
                                    react_1.default.createElement("hr", null),
                                    react_1.default.createElement("a", { href: "https://github.com/moquette/", target: "_blank", className: "navbar-link", rel: "noreferrer" },
                                        react_1.default.createElement(ri_1.RiGithubLine, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "GitHub"),
                                    react_1.default.createElement("a", { href: "https://www.linkedin.com/in/jmoquette/", target: "_blank", className: "navbar-link", rel: "noreferrer" },
                                        react_1.default.createElement(ri_1.RiLinkedinLine, { className: "ml-2 mr-5 inline-block text-3xl" }),
                                        "LinkedIn"))))))))))));
};
exports.Nav = Nav;
